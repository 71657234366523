import React from "react";

const Education = ({ color }) => {
  return (
    <svg viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10552_35252)">
        <path
          d="M2.91674 7.68906V9.32823C2.91674 9.75406 3.15008 10.1507 3.52341 10.3549L6.44008 11.9474C6.79008 12.1399 7.21008 12.1399 7.56008 11.9474L10.4767 10.3549C10.8501 10.1507 11.0834 9.75406 11.0834 9.32823V7.68906L7.56008 9.61406C7.21008 9.80656 6.79008 9.80656 6.44008 9.61406L2.91674 7.68906ZM6.44008 2.05406L1.52258 4.7374C1.12008 4.95906 1.12008 5.5424 1.52258 5.76406L6.44008 8.4474C6.79008 8.6399 7.21008 8.6399 7.56008 8.4474L12.2501 5.88656V9.33406C12.2501 9.6549 12.5126 9.9174 12.8334 9.9174C13.1542 9.9174 13.4167 9.6549 13.4167 9.33406V5.5949C13.4167 5.37906 13.3001 5.18656 13.1134 5.08156L7.56008 2.05406C7.21008 1.8674 6.79008 1.8674 6.44008 2.05406Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_10552_35252">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Education;
